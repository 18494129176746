import { defineStore } from 'pinia'
import SearchService from '~/services/SearchService'

export const useSearchStore = defineStore('search', {
  state: () => ({
    searchText: '',
    searchResults: [],
    searchResultSelected: {},
    isLoading: false,
    noResultsFound: false,
  }),
  actions: {
    setSearchText(text: string) {
      this.searchText = text
      this.noResultsFound = false
      this.searchResultSelected = {}
    },
    async search() {
      if (this.searchText.length < 4) {
        this.searchResults = []
        return
      }
      this.isLoading = true
      this.searchResults = await SearchService.getPredictions(this.searchText)
      this.isLoading = false
      this.noResultsFound = this.searchResults.length === 0
    },
  },
})
