<script setup lang="ts"></script>

<template>
  <div
    class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
    <div
      class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
      <div class="relative">
        <img
          src="/img/index/hero/index-hero-img-5 Medium.webp"
          alt="index-hero-img-5"
          width="400"
          height="400"
          class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
        <div
          class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
      </div>
    </div>
    <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
      <div class="relative">
        <img
          src="/img/index/hero/index-hero-img-2 Medium.webp"
          alt="index-hero-img-2"
          width="400"
          height="400"
          class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
        <div
          class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
      </div>
      <div class="relative">
        <img
          src="/img/index/hero/index-hero-img-3 Medium.webp"
          alt="index-hero-img-3"
          width="400"
          height="400"
          class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
        <div
          class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
      </div>
    </div>
    <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
      <div class="relative">
        <img
          src="/img/index/hero/index-hero-img-4 Medium.webp"
          alt="index-hero-img-4"
          width="400"
          height="400"
          class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
        <div
          class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
      </div>
      <div class="relative">
        <img
          src="/img/index/hero/index-hero-img-1 Medium.webp"
          alt="index-hero-img-1"
          width="400"
          height="400"
          class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
        <div
          class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
      </div>
    </div>
  </div>
</template>
