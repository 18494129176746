<script setup lang="ts">
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
  import { MapPinIcon } from '@heroicons/vue/24/outline'
  import { useSearchStore } from '~/stores/searchStore'
  import { useStringHelpers } from '~/composables/useStringHelpers'
  import SpaceService from '~/services/SpaceService'

  const router = useRouter()
  const searchStore = useSearchStore()
  const { normalizeStringForUrl } = useStringHelpers()

  const handleInputChange = (event: any) => {
    searchStore.setSearchText(event.target.value)
    searchStore.search()
  }

  const handleResultClick = (result: any) => {
    if (result.searchType === 'address') {
      searchStore.setSearchText(`${result.neighborhood} - ${result.city}`)
      searchStore.searchResultSelected = {
        neighborhood: result.neighborhood,
        city: result.city,
        searchType: result.searchType,
      }
    }
    if (result.searchType === 'space') {
      searchStore.setSearchText(result.spaceName)
      searchStore.searchResultSelected = {
        spaceId: result.spaceId,
        spaceName: result.spaceName,
        searchType: result.searchType,
      }
    }
    searchStore.searchResults = []
  }

  const handleSearchButtonClick = async () => {
    if (searchStore.searchResultSelected.searchType === 'address') {
      router.push({
        name: 'search-city-neighborhood',
        params: {
          city: normalizeStringForUrl(searchStore.searchResultSelected.city),
          neighborhood: normalizeStringForUrl(
            searchStore.searchResultSelected.neighborhood
          ),
        },
      })
    }
    if (searchStore.searchResultSelected.searchType === 'space') {
      const space = await SpaceService.getSpaceById(
        searchStore.searchResultSelected.spaceId.toString()
      )
      router.push({
        name: 'space',
        params: {
          id: space.id,
          city: normalizeStringForUrl(space.address.city),
          neighborhood: normalizeStringForUrl(space.address.neighborhood),
          name: normalizeStringForUrl(space.name),
        },
      })
    }
  }
</script>

<template>
  <div
    class="relative"
    style="--search-width: 100%">
    <div class="mt-4 rounded-md shadow-sm md:flex">
      <div class="relative flex flex-grow items-stretch focus-within:z-10">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MapPinIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" />
        </div>
        <input
          id="searchText"
          v-model="searchStore.searchText"
          type="text"
          name="searchText"
          class="block w-full rounded-l-md rounded-r-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-gray-400 sm:text-sm sm:leading-6 md:rounded-r-none"
          placeholder="Digite o nome de um espaço, bairro, cidade ou serviço"
          @input="handleInputChange" />
      </div>
      <button
        type="button"
        class="z-20 -ml-px mt-2 flex w-full items-center justify-center gap-x-1.5 rounded-l-md rounded-r-md bg-orange-400 px-3 py-1 text-sm font-semibold text-white ring-1 ring-inset ring-orange-300 hover:bg-orange-500 md:relative md:mt-0 md:inline-flex md:w-fit md:rounded-l-none md:rounded-r-md md:py-2"
        @click="handleSearchButtonClick">
        <MagnifyingGlassIcon
          class="-ml-0.5 h-10 w-10 py-2 text-white md:h-5 md:w-5 md:py-0"
          aria-hidden="true" />
        <span class="">Pesquisar</span>
      </button>
    </div>
    <div
      v-if="searchStore.searchResults.length > 0"
      class="absolute z-10 mt-2 max-h-80 w-full overflow-y-auto rounded-md bg-white p-4 shadow"
      :style="{ width: 'var(--search-width)' }">
      <ul>
        <li
          v-for="result in searchStore.searchResults"
          :key="result.neighborhood"
          class="flex transform justify-between px-2 py-2 transition-all duration-200 ease-out hover:cursor-pointer hover:border-l-2 hover:border-orange-400"
          @click="handleResultClick(result)">
          <span
            v-if="result.searchType === 'address'"
            class="text-sm text-gray-900"
            >{{ result.neighborhood }} - {{ result.city }}</span
          >
          <span
            v-if="result.searchType === 'space'"
            class="text-sm text-gray-900"
            >{{ result.spaceName }}</span
          >
          <span
            class="ml-3 inline-flex items-center rounded-md bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-700"
            >{{ result.total }} {{ result.total > 1 ? 'espaços' : 'espaço' }}
          </span>
        </li>
      </ul>
    </div>
    <div
      v-if="
        searchStore.noResultsFound &&
        searchStore.searchText.length >= 4 &&
        !searchStore.isLoading
      "
      class="absolute z-10 mt-2 w-full rounded-md bg-white p-4 text-gray-500 shadow"
      :style="{ width: 'var(--search-width)' }">
      Nenhum resultado encontrado
    </div>
  </div>
</template>
