<script setup lang="ts">
  import type { Ref } from 'vue'
  import SiteIndexHero from '~/components/site/SiteIndexHero.vue'
  import SpaceCard from '~/components/cards/SpaceCard.vue'
  import SpaceService from '~/services/SpaceService'
  import { useSeoHelpers } from '~/composables/useSeoHelpers'

  const featuredSpaces = ref<Ref<any>>()
  useSeoHelpers().createIndexPageHead()

  const getFeaturedSpaces = async () => {
    try {
      featuredSpaces.value = await SpaceService.getFeaturedSpaces()
    } catch (error: any) {}
  }

  await getFeaturedSpaces()
</script>
<template>
  <div>
    <SiteIndexHero />
    <LazySiteIndexStats />
    <!--    <AdsDisplay
      class="mx-auto mb-24 grid max-w-7xl grid-cols-1 items-center pl-3" />-->
    <ul
      class="mx-auto grid max-w-7xl grid-cols-1 items-center gap-y-0 sm:grid-cols-2 sm:gap-y-7 md:grid-cols-3 lg:grid-cols-4">
      <li
        v-for="space in featuredSpaces"
        :key="space.name">
        <SpaceCard :space="space" />
      </li>
    </ul>
    <LazySiteIndexCallToAction />
    <!--    <AdsDisplay
      class="mx-auto mb-6 mt-16 grid max-w-7xl grid-cols-1 items-center pl-10" />-->
  </div>
</template>
