import type { Ref } from 'vue'
import { FetchError } from 'ofetch'
import { RestService } from '~/services/RestService'
import Endpoints from '~/models/constants/Endpoints'

class SearchService extends RestService {
  async getPredictions(text: string) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(
        `${Endpoints.SEARCH}/predictions`,
        {
          query: {
            searchText: text,
          },
        },
        true
      )

    // @ts-ignore
    if (response.error.value !== null) {
      // @ts-ignore
      throw createError(response.error.value.data)
    } else {
      // @ts-ignore
      return response.data.value
    }
  }

  async searchSpaces(filters: any) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(`${Endpoints.SPACES}`, {
        params: filters,
        watch: [filters],
      })

    return this.handleResponse(response)
  }

  async searchSpacesCount(filters: any) {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.find(`${Endpoints.SPACES}/count`, {
        params: filters,
        watch: [filters],
      })

    return this.handleResponse(response)
  }
}

export default new SearchService()
